import { Form, Input } from 'antd-mobile';
import { useTranslation } from 'react-i18next';

interface IProps {
	name: string;
	label?: JSX.Element | string;
	rules?: any[];
	placeholder?: string;
	type?: string;
	disabled?: boolean;
	className?: string;
	max?: number;
	dependencies?: string[];
}

const FormInput = ({
	name,
	label,
	rules = [],
	placeholder,
	type = 'text',
	disabled = false,
	className,
	max,
	dependencies,
}: IProps) => {
	const { t } = useTranslation();
	const customPlaceholder = placeholder ? placeholder : t('formCommon.pleaseInput');

	return (
		<Form.Item name={name} label={label} rules={rules} dependencies={dependencies}>
			<Input
				className={className}
				disabled={disabled}
				type={type}
				placeholder={customPlaceholder}
				style={{ '--font-size': '14px' }}
				max={max}
			/>
		</Form.Item>
	);
};
export default FormInput;
