import { CheckList, Popup, SearchBar } from 'antd-mobile';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface IProps {
	isProductFamily?: boolean;
	customComponent?: any;
	onChange?: (value: any) => void;
	value?: any;
	options: Array<any> | (() => Array<any>);
	title: string;
	onCustomChange?: (val: string) => void;
	search?: boolean;
	onCancel?: () => void;
	showValue?: boolean;
	setSearchResultProductFamily?: (value: any) => void;
}

const PopupList = forwardRef(
	(
		{
			onChange,
			value,
			options,
			title,
			onCustomChange,
			search = false,
			onCancel,
			showValue = true,
			isProductFamily = false,
			customComponent = null,
			setSearchResultProductFamily,
		}: IProps,
		ref: any
	) => {
		const [visible, setVisible] = useState(false);
		const [, setSelectValue] = useState('');
		const [searchText, setSearchText] = useState('');
		const [scrollTopValue, setScrollTopValue] = useState(0);
		const searchBarRef = useRef<any>();
		const popupChecklistRef = useRef<any>(null);
		const { t } = useTranslation();
		const language = useSelector<any>((data: any) => data?.user?.language);
		useImperativeHandle(
			ref,
			() => {
				const openVisible = () => {
					setVisible(true);
				};
				const closeVisible = () => {
					setVisible(true);
				};
				return { openVisible, closeVisible };
			},
			[]
		);

		useEffect(() => {
			if (onCancel) {
				!visible && onCancel();
			}
		}, [visible]);

		useEffect(() => {
			setTimeout(() => {
				const popupChecklist = popupChecklistRef.current;
				if (popupChecklist) {
					const hideVirtualKeyboard = () => {
						searchBarRef.current.blur();
					};

					popupChecklist.addEventListener('touchmove', hideVirtualKeyboard);

					return () => {
						popupChecklist.removeEventListener('touchmove', hideVirtualKeyboard);
					};
				}
			}, 100);
		}, [visible]);

		const renderOptions = (param: any) => {
			let optionsArr = typeof options === 'function' ? options() : options;
			if (search) {
				optionsArr = optionsArr.filter((item) => {
					if (language !== 'EN') {
						return t(item.label).includes(searchText);
					} else {
						return t(item.label).toLowerCase().includes(searchText.toLowerCase());
					}
				});
			}
			return optionsArr.map((item, index) => {
				return (
					<CheckList.Item
						key={`${item.value}${index}`}
						value={item.value}
						className={param === item.value ? 'text-primary' : ''}
					>
						{item.label}
					</CheckList.Item>
				);
			});
		};

		const handleClose = () => {
			setVisible(false);
			setSearchText('');
			searchBarRef?.current && searchBarRef.current.clear();
		};

		const getPopupCheckListContentDom = () => document.getElementById('popup_check_list_content');

		useEffect(() => {
			const optionsArr = typeof options === 'function' ? options() : options;

			const filteredOptions = optionsArr.filter((item) => {
				if (language !== 'EN') {
					return t(item.label).includes(searchText);
				} else {
					return t(item.label).toLowerCase().includes(searchText.toLowerCase());
				}
			});

			if (setSearchResultProductFamily) {
				if (searchText) {
					setSearchResultProductFamily({ searchText, searchCount: filteredOptions.length });
				}
			}
		}, [searchText, language, t, setSearchResultProductFamily, options]);

		useEffect(() => {
			if (visible) {
				setTimeout(() => {
					if (searchBarRef.current) {
						searchBarRef.current.focus();
					}
				}, 100);
			} else {
				setSearchText('');
				searchBarRef?.current && searchBarRef.current.clear();
			}
		}, [visible, searchBarRef]);

		return (
			<div>
				{showValue &&
					(value ? (
						<span className='text-[14px]'>{value}</span>
					) : (
						<span className='text-placeholder_gray text-[14px]'>
							{t('formCommon.pleaseSelect')}
						</span>
					))}
				<Popup
					visible={visible}
					onMaskClick={handleClose}
					destroyOnClose
					bodyClassName='form_popup'
					afterShow={() => {
						getPopupCheckListContentDom()?.scrollTo({
							top: scrollTopValue,
							left: 0,
							behavior: 'smooth',
						});
					}}
					bodyStyle={{
						height: isProductFamily ? '85vh' : '50vh',
						borderRadius: isProductFamily ? '8px' : 0,
						display: 'flex',
						flexDirection: 'column',
					}}
					onClose={handleClose}
				>
					<div className='m-4 flex items-center justify-center'>{title}</div>
					{search && (
						<SearchBar
							ref={searchBarRef}
							className='m-2'
							placeholder={`${t('productGenericForm.productFamilyPopSearchPlac')}`}
							value={searchText}
							onFocus={() => {
								setTimeout(() => {
									document.getElementsByClassName('adm-popup')[0].scrollIntoView();
								}, 100);
							}}
							onClear={() => {
								getPopupCheckListContentDom()?.scrollTo({
									top: 0,
									left: 0,
									behavior: 'smooth',
								});
							}}
							onChange={(v) => {
								setSearchText(v);
							}}
						/>
					)}
					{isProductFamily && <div onClick={() => setVisible(false)}>{customComponent}</div>}
					<div
						id='popup_check_list_content'
						ref={popupChecklistRef}
						className='grow overflow-y-auto'
					>
						<CheckList
							multiple={false}
							onChange={(val: any) => {
								setScrollTopValue(getPopupCheckListContentDom()?.scrollTop || 0);
								onCustomChange && onCustomChange(val[0]);
								onChange && onChange(val[0]);
								setSelectValue(val[0]);
								setVisible(false);
							}}
							value={[value]}
							style={{
								'--font-size': '14px',
							}}
						>
							{renderOptions(value)}
						</CheckList>
					</div>
				</Popup>
			</div>
		);
	}
);

export { PopupList };
