import { Skeleton } from 'antd-mobile';
import { lazy, Suspense } from 'react';

const LoadingFallback = () => (
	<div className='flex justify-center'>
		<Skeleton.Title animated />
		<Skeleton.Paragraph lineCount={10} animated />
	</div>
);

const LazyLoad = ({ children }: { children: JSX.Element }) => {
	return <Suspense fallback={<LoadingFallback />}>{children}</Suspense>;
};

const HomePage = lazy(() => import('./Home'));
const LazyHome = () => {
	return (
		<LazyLoad>
			<HomePage />
		</LazyLoad>
	);
};

const FormPage = lazy(() => import('./Form'));
const LazyFormPage = () => {
	return (
		<LazyLoad>
			<FormPage />
		</LazyLoad>
	);
};

const ContentHubPage = lazy(() => import('./ContentHub/index'));
const LazyContentHubPage = () => {
	return (
		<LazyLoad>
			<ContentHubPage />
		</LazyLoad>
	);
};

const ContentHubDetailsPage = lazy(() => import('./ContentHub/details'));
const LazyContentHubDetailsPage = () => {
	return (
		<LazyLoad>
			<ContentHubDetailsPage />
		</LazyLoad>
	);
};

const ContentHubViewPage = lazy(() => import('./ContentHub/display'));
const LazyContentHubViewPage = () => {
	return (
		<LazyLoad>
			<ContentHubViewPage />
		</LazyLoad>
	);
};

const LeadStatusReport = lazy(() => import('./LeadStatusReport/index'));
const LazyLeadStatusReport = () => {
	return (
		<LazyLoad>
			<LeadStatusReport />
		</LazyLoad>
	);
};

const LeadAdminPanel = lazy(() => import('./AdminPanel/index'));
const LazyLeadAdminPanel = () => {
	return (
		<LazyLoad>
			<LeadAdminPanel />
		</LazyLoad>
	);
};

const SurveyHubPage = lazy(() => import('./SurveyHub/index'));
const LazySurveyHubPage = () => {
	return (
		<LazyLoad>
			<SurveyHubPage />
		</LazyLoad>
	);
};

const TranslationalRequest = lazy(() => import('./TranslationalRequest/index'));
const LazyTranslationalRequest = () => {
	return (
		<LazyLoad>
			<TranslationalRequest />
		</LazyLoad>
	);
};

export {
	LazyHome,
	LazyFormPage,
	LazyContentHubPage,
	LazyContentHubDetailsPage,
	LazyContentHubViewPage,
	LazyLeadStatusReport,
	LazyLeadAdminPanel,
	LazySurveyHubPage,
	LazyTranslationalRequest,
};
